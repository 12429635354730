@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
body {
  overflow-x: hidden;
}

section {
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

/**
 * Site header
 */
.header {
  width: 100%;
  max-width: 1240px;
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;

  .link-lang {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 21.86px;
    color: #020113;
    opacity: 0.7;
    position: relative;

    &:first-child {
      margin-right: 16px;
    }

    &.active {
      font-weight: 200;
      opacity: 1;

      &::after {
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        left: 0;
        right: 0;
        top: 20px;
        margin: auto;
        border-radius: 50%;
        background: #06050d;
      }
    }
  }

  @include media-query($on-palm) {
    display: flex;
    justify-content: space-between;
  }
}
.container {
  width: 100%;
  max-width: 1241px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.site-header {
  display: flex;
  justify-content: space-between;
  min-height: calc($spacing-unit * 1.865);
  line-height: calc($base-line-height * $base-font-size * 2.25);
  position: relative;
}

.logo {
  color: #020113;
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 32px;
  line-height: 150%;
  text-transform: lowercase;
  :hover {
    text-decoration: none;
    color: #020113;
  }
  @include media-query($on-palm) {
    padding-right: 45px;
    padding-left: 16px;
  }
}

.disclime-header {
  display: none;
}
.site-nav {
  display: flex;
  width: 100%;
  align-items: center;
  .nav-trigger {
    display: none;
  }
  .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
  }
  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }
  .page-link {
    display: block;
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: #020113;
    border-bottom: none;

    color: $text-color;
    line-height: $base-line-height;
    padding: 5px 10px;
    &:not(:last-child) {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 52px;
    }
    margin-left: 2px;
  }
  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
      margin-left: auto;
    }
  }
}
.header {
  padding-top: 9px;
}
.site-nav1 {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 22px;
  color: #020113;
  display: flex;
  justify-content: center;
  max-width: 757px;
  .menu1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-trigger {
    display: none;
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;
    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
  }

  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
      margin-left: auto;
    }
  }
}
.menu-right {
  a {
    font-family: "Questrial", sans-serif;

    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 25px;
    color: #020113;
  }
  width: 100%;
  max-width: 254px;
  display: flex;
  justify-content: end;
  div {
    padding: 0 24px;
  }
}
/**
 *temporary
 */
.lang {
  display: none;
}
/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc($spacing-unit / 2);
}

.feed-subscribe .svg-icon {
  padding: 5px 5px 2px 0;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.site-footer-contact {
  padding-bottom: 50px;
  border-bottom: 1px solid #e8e8e8;
}
/**
 * site-footer

 */

.site-footer {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: unset;
    .footer-link {
      width: 100%;
      max-width: 70px;
      font-family: "Questrial", sans-serif;
      font-weight: 200;
      font-size: 16px;
      line-height: 22px;
      color: #020113;
    }
  }
}

.nav-item {
  padding: 12px 40px;
  list-style: none;
}
/**
Footer
*/

.menu-footer {
  .mail-footer {
    padding: 12px 0 24px 0;
  }
  display: flex;
  justify-content: space-between;
  max-width: 437px;
  width: 100%;
  ul {
    margin: unset;
  }

  .footer-col:first-child {
    li {
      padding-left: unset;
    }
  }
  .footer-col:last-child {
    padding: 0 100px;
    display: flex;
    flex-wrap: wrap;
    max-width: 190px;
    a {
      font-family: "Questrial", sans-serif;
      font-size: 14px;
      line-height: 19.2px;
      color: #020113;
    }
  }
}
.disclaimer {
  p {
    padding-top: 108px;
    max-width: 391px;
    font-family: "Questrial", sans-serif;
    font-size: 12px;
    line-height: 21px;
    color: #7c7c7c;
  }
}
.footer-contact {
  display: flex;
  justify-content: space-between;
  max-width: 436px;
  width: 100%;
}
.footer-container {
  width: 100%;
  max-width: 1440px;
  margin: unset;
}

.contacts {
  display: flex;
  justify-content: space-between;
  max-width: 436px;
  width: 100%;
}
.contact-col {
  p {
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 180%;
    margin-bottom: 0px;
    color: #06050d;
  }
}

.contact-col {
  a {
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 160%;
    color: #7c7c7c;
    text-decoration-line: underline;
  }
}
.phone-footer {
  padding-bottom: 7px;
}
/**
 * Page content
 */
.home {
  width: 100%;
  max-width: 1241px;
}

.h1home {
  padding: 139px 0;
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
  h1 {
    // &:hover {
    //   background: rgba(0, 0, 0, 0.1);
    // }
    background: transparent;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
    margin-bottom: 0px;
    width: 1020px;
    left: 206px;
    top: 179px;

    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 64px;
    line-height: 86px;

    color: #020113;
    text-align: center;
  }
}
.about-us {
  padding: 40px 0;
  border-top: 8px solid;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .upwork-top p {
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 18.54px;
  }
  .description {
    font-family: "Questrial", sans-serif;
    max-width: 457px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: right;
    text-transform: uppercase;

    color: #7c7c7c;
  }
  .about-service {
    padding-right: 20px;
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 28.8px;
    text-align: right;
    color: #020113;
  }
}
.products {
  width: 100%;
  max-width: 1240px;
}
.projects .container {
  padding-top: 114px;
  padding-bottom: 120px;
}
.selected-projects {
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28.84px;
  text-transform: uppercase;
  padding-bottom: 22px;
  color: #020113;
  margin: unset;
}
.row {
  width: 50%;
}
.sample-rectange {
  max-width: 442px;
  max-height: 562px;
  width: 100%;
  height: 100%;
  color: black;
}

#what-we-do {
  padding: 104px 0;
  width: 100%;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

/**
*Contact form
*/
#footer-contact-page {
  border-top: 1px solid $border-color-01;
  margin-left: 100px;
  margin-right: 100px;
  padding: 48px 0 0;

  .disclaimer {
    padding-top: 80px;
    padding-bottom: 50px;
    margin: 0;
    border: 0;

    p {
      padding-top: 0;
    }
  }
}

#contact-form {
  padding-top: 62px;
  padding-bottom: 184px;
}

.start-conversation {
  font-family: "Questrial", sans-serif;

  padding: 106px 0 15px 0;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  .h2-text {
    font-family: "Questrial", sans-serif;

    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 150%;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
}
.contact-title {
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 72px;
  line-height: 97px;
  color: #020113;
}
.form-group button {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  max-width: 712px;
  width: 100%;
  height: 49px;
  background: #06050d;
  color: #ffffff;
  align-items: center;
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 19px;
}
.form-group input {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  color: #06050d;
  max-width: 332px;
  width: 100%;
  padding-top: 12px;
  margin-bottom: 24px;
  padding-bottom: 8px;
}
.form-col select {
  margin-bottom: 24px;
}
.form-col select {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  max-width: 332px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 8px;
  appearance: none;
  background: #ffffff;
}
.form-col option {
  background: #020113;
  color: #7c7c7c;
}

.form-col label {
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: #020113;
}
.form-cols .form-control {
  font-family: "Questrial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 180%;
  padding-left: 8px;
}

/* (A2) DEFINE OUR OWN CUSTOM ARROW */
// .form-col-select::after {
//   content: "\25CF";
//   margin-left: -25px;
// }

/* (A3) POSITION CUSTOM ARROW */
.form-col-select {
  position: relative;
}
.form-group {
  max-width: 710px;
  width: 100%;
}
.contact-cols {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact-cols > div:first-child {
  max-width: 357px;
  margin-right: 36px;
  margin-bottom: 72px;
}
.form-cols {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact-col {
  width: 100%;
  max-width: 712px;
}
.form-col {
  max-width: 356px;
  width: 100%;
}
.contact-form > .form-group {
  max-width: unset;
}
.contact-form select {
  width: calc(100% - 15px);
}
.contact-form > div:last-child {
  display: flex;
  justify-content: center;
}
/**
*END Contact form
*/
.project-image:hover {
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.6250000000000228, 0, 1);
  transition: 0.5s ease;
  opacity: 0.7;
}

.section-name {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  .h2-text {
    font-family: "Questrial", sans-serif;

    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
}
.project-cols {
  display: flex;
  justify-content: space-between;
  img {
    width: 100%;
  }
}
.project-cols:first-child {
  display: flex;
  justify-content: space-between;
}
.project-cols > div:first-child {
  padding-top: 166px;
  div:last-child > img {
    max-width: 498px;
  }
  div:first-child > img {
    max-width: 448px;
  }
}
.project-cols > div:last-child {
  div:last-child > img {
    max-width: 538px;
  }
  div:first-child > img {
    max-width: 604px;
  }
}
.project-col {
  max-width: 50%;
}
.project {
  h2 {
    font-family: "Questrial", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 24.72px;
  }
  p {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 28.8px;
    color: #7c7c7c;
  }
  .project-name {
    display: flex;
    align-items: baseline;
    padding: 42px 0;

    & > p {
      font-size: 16px;
      line-height: 28.8px;
      margin-right: 32px;
    }
  }
}
.what-we-do-links {
  margin: auto;
  padding-top: 15px;
  .links {
    width: 100%;
  }
  .link {
    padding: 32px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    max-width: 1028px;
    margin: auto;
    p {
      margin-bottom: unset;
      font-family: "Questrial", sans-serif;
      font-style: normal;
      font-weight: 200;
      font-size: 24px;
      line-height: 38.4px;
      color: #020113;
    }
    a {
      font-family: "Questrial", sans-serif;
      font-weight: 200;
      font-size: 18px;
      line-height: 28.8px;
      text-align: right;
      text-decoration-line: underline;

      color: #7c7c7c;
    }
  }
  .link:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
  .links:last-child {
    border-bottom: unset;
  }
}

#get-in-touch {
  padding-bottom: 155px;
  border-bottom: 1px solid #e8e8e8;
  a {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 64px;
    line-height: 65.92px;
    color: #020113;
    padding-bottom: 185px;
  }
  p {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    color: #7c7c7c;
    padding-top: 120px;
    padding-bottom: 40px;
    margin-bottom: 52px;
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 24px;
    line-height: 24.72px;
    color: #020113;
  }
}
#get-in-touchnew {
  padding-bottom: 155px;
  border-bottom: 1px solid #e8e8e8;
  a {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 68px;
    line-height: 86.77px;
    color: #020113;
    padding-bottom: 185px;
  }
  p {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    color: #7c7c7c;
    padding-top: 120px;
    padding-bottom: 40px;
    margin-bottom: 52px;
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 25px;
    line-height: 31.9px;
    color: #020113;
  }
}
#get-in-touch-cookie {
  a {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 64px;
    line-height: 65.92px;
    color: #020113;
  }
  p {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    color: #7c7c7c;
    padding-top: 120px;
    padding-bottom: 40px;
    margin-bottom: 52px;
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 24px;
    line-height: 24.72px;
    color: #020113;
  }
}

.cookie-header {
  padding-top: 62px;
  margin: 0 100px;
}
.projects-row {
  display: flex;
  justify-content: space-between;
}
.page-content {
  padding: 0 0 12px;
}

.view-all {
  padding-top: 70px;
  padding-bottom: 40px;
  border-bottom: #000 1px solid;
}

.what-we-do-link {
  margin-right: 22px;
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 38.4px;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $brand-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1.15;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.625);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1,
  h2,
  h3 {
    margin-top: calc($spacing-unit * 2);
  }
  h4,
  h5,
  h6 {
    margin-top: $spacing-unit;
  }

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h4 {
    @include relative-font-size(1.25);
  }

  h5 {
    @include relative-font-size(1.125);
  }
  h6 {
    @include relative-font-size(1.0625);
  }
}

.social-media-list {
  display: table;
  margin: 0 auto;
  li {
    float: left;
    margin: 5px 10px 5px 0;
    &:last-of-type {
      margin-right: 0;
    }
    a {
      display: block;
      padding: calc($spacing-unit / 4);
      &:hover {
        border-color: $border-color-02;
      }
    }
  }
}

/**
 * Pagination navbar
 */
.pagination {
  margin-bottom: $spacing-unit;
  @extend .social-media-list;
  li {
    a,
    div {
      min-width: 41px;
      text-align: center;
      box-sizing: border-box;
    }
    div {
      display: block;
      padding: calc($spacing-unit / 4);
      border: 1px solid transparent;

      &.pager-edge {
        color: $border-color-01;
        border: 1px dashed;
      }
    }
  }
}

/**
 * Grid helpers
 */
@media screen and (min-width: $on-large) {
  .one-half {
    width: calc(50% - (#{$spacing-unit} / 2));
  }
}

/**
*SERVICES
*/
#services {
  padding-bottom: 76px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .ticker-wrap + .container {
    position: relative;

    .line {
      margin-top: 45px;
      position: absolute;
      border-top: 1px solid #e8e8e8;
      width: 100%;
    }
  }
  .ticker-wrapnew + .container {
    position: relative;

    .line {
      margin-top: 96px;
      position: absolute;
      border-top: 1px solid #e8e8e8;
      width: 100%;
    }
  }
  .ticker-wrap-services {
    padding-top: 64px;
  }
}
#services > .container:nth-child(2) {
  padding-top: 106px;
}
#services > .container:nth-child(3) {
  padding-top: 64px;
}
#services > .container:nth-child(4) {
  border-bottom: 1px solid #e8e8e8;
}

#services > .container:nth-child(4) > .container {
  max-width: 392px;
}
#services > div:last-child {
  padding-top: 10px;
}

.step-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1240px;
  margin: auto;
  padding-top: 133px;
  padding-bottom: 64px;
}
.step-container .container {
  max-width: 392px;
  flex-wrap: wrap;
  max-height: 450px;
  height: 100%;
}
.h2-services {
  padding: 40px 0 0 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;

  &.main-header {
    padding: 0 0 40px 0;
    width: 100%;
    border-top: none;
  }

  .h2-text {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
}
.h2-servicesnew {
  padding: 40px 0 0 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;

  &.main-header {
    padding: 0 0 40px 0;
    width: 100%;
    border-top: none;
  }

  .h2-text {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
}
.who-are-we p {
  padding-top: 298px;
  max-width: 500px;
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 22px;
  line-height: 35.2px;
  /* or 35px */

  color: #020113;
}
.who-are-wenew p {
  padding-top: 298px;
  max-width: 500px;
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 22px;
  line-height: 35.2px;
  /* or 35px */

  color: #020113;
}
.h3-text {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 36px;
  line-height: 57.6px;
  margin-bottom: 0;
  /* or 58px */

  max-width: 500px;
  padding: 40px 0;
  color: #020113;
}
.h3-textnew {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 36px;
  line-height: 57.6px;
  margin-bottom: 0;
  /* or 58px */

  max-width: 500px;
  padding: 40px 0;
  color: #020113;
}
.step-title {
  font-family: "Questrial", sans-serif;

  display: flex;
  align-items: center;
  padding-right: 40px;
}
.step-titlenew {
  font-family: "Questrial", sans-serif;

  display: flex;
  align-items: center;
  padding-right: 40px;
}
.step-title > p {
  margin-bottom: 32px;
}
.step-titlenew > p {
  margin-bottom: 32px;
}
.step-descriptionnew > p {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 18px;
  line-height: 28.8px;
  margin-bottom: 40px;
  /* or 29px */

  /* Nordicsoft/Description */

  color: #7c7c7c;
}
.step-number {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 32px;
  line-height: 43.71px;
  padding-right: 40px;
  /* identical to box height */

  /* Nordicsoft / Main */

  color: #06050d;
}
.step-numbernew {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 32px;
  line-height: 43.71px;
  padding-right: 40px;
  /* identical to box height */

  /* Nordicsoft / Main */

  color: #06050d;
}
.step-name {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 32px;
  line-height: 43.71px;

  /* Nordicsoft / Main */

  color: #06050d;
}
.step-namenew {
  font-size: 32px;
  line-height: 43.71px;

  /* Nordicsoft / Main */

  color: #06050d;
}
.steps {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 18px;
  line-height: 28.8px;
  color: #020113;
  margin-bottom: 17px;

  .steps-text {
    padding: 8px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #e8e8e8;
  }
}
.stepsnew {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 18px;
  line-height: 28.8px;
  color: #020113;
  margin-bottom: 17px;

  .steps-textnew {
    padding: 8px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #e8e8e8;
  }
}

.clients {
  background-color: #f5f5f5;
  padding-top: 104px;
  padding-bottom: 136px;
}
.h2-clients {
  padding: 0 0 40px 0;
  width: 100%;
  display: flex;
  align-items: center;
  .h2-text {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
  .h2-textnew {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
}
.clients .h3-text {
  padding-bottom: 48px;
  padding-top: 8px;
}
.clients .h3-textnew {
  padding-bottom: 48px;
  padding-top: 8px;
}
.clients-testimonials {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 48px 15px;
  border-top: 1px solid #e0e0e0;
}
.testimonial {
  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .client-title {
      padding-top: 32px;
    }
  }
  .testimonial-description {
    max-width: 70%;
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 18px;
    line-height: 28.8px;
    padding-left: 32px;
  }
  .testimonial-descriptionnew {
    max-width: 246px;
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 18px;
    line-height: 28.8px;
    padding-left: 32px;
  }
}
.client-title > p:first-child {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 18px;
  line-height: 24.59px;
  color: #06050d;
}
.client-titlenew > p:first-child {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 18px;
  line-height: 24.59px;
  color: #06050d;
}
.client-title > p:last-child {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 12px;
  line-height: 21.2px;
  color: #7c7c7c;
}
.client-titlenew > p:last-child {
  font-family: "Questrial", sans-serif;
  font-weight: 200;
  font-size: 12px;
  line-height: 21.2px;
  color: #7c7c7c;
}
$duration: 20s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  top: 212px;
  position: absolute;
  width: 150%;
  padding-left: 100%;
  z-index: 1;
  .ticker__item {
    color: #020113;
  }
  .ticker {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
    &__item {
      font-family: "Questrial", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 72px;
      line-height: 74.16px;
      color: #06050d;
    }
  }
}

.ticker-wrapnew {
  top: 240px;
  position: absolute;
  width: 150%;
  padding-left: 100%;
  z-index: 1;
  .ticker__item {
    color: #020113;
  }
  .ticker {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
    &__item {
      font-family: "Questrial", sans-serif;
      font-style: normal;
      font-weight: 200;
      font-size: 72px;
      line-height: 98px;
      color: #06050d;
    }
  }
}
#get-in-touch-cookie .cookie-footer-text {
  padding: 180px 0 150px;
  border-bottom: 1px solid #e0e0e0;
}
#get-in-touch-cookie .cookie-footer .cookie-footer-text {
  text-align: center;
  .h2-text {
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 56px;
    line-height: 76px;
    color: #020113;
    padding-bottom: 40px;
  }
  a {
    font-family: "Questrial", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 160%;
    text-decoration-line: underline;
    color: #7c7c7c;
    margin-bottom: 0;
  }
}

.address-social {
  display: flex;
  max-width: 231px;
  flex-wrap: wrap;
  justify-content: right;
}
.address {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  a {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    color: #020113;
    text-align: right;
  }
}
.social-links a {
  padding: 12px;
}
.social-links a:last-child {
  padding-right: unset;
}

#projects {
  padding-top: 106px;
  .projects .container {
    padding: 0;
  }
  .start-conversation {
    padding-top: 0;
  }
  .project {
    padding-top: 100px;
  }
  .project-head {
    display: flex;
    justify-content: space-between;
    padding-top: 64px;

    h1 {
      color: #020113;
      font-family: "Questrial", sans-serif;
      font-weight: 200;
      font-size: 72px;
      line-height: 97px;
    }

    .project-col-head {
      max-width: 498px;

      p {
        font-family: "Questrial", sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 22px;
        line-height: 160%;
      }
    }
  }

  .project-cols {
    .project-col {
      &:first-child {
        .project:last-child .project-name {
          padding-bottom: 120px;
        }
      }
    }
  }

  .project-down {
    padding: 120px 0 160px;
    border-bottom: 1px solid #e0e0e0;
    .project-foot-text {
      text-align: center;

      .h2-text {
        font-family: "Questrial", sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 56px;
        line-height: 76px;
        color: #020113;
        padding-bottom: 40px;
      }

      a {
        font-family: "Questrial", sans-serif;

        font-style: normal;
        font-weight: 200;
        font-size: 18px;
        line-height: 160%;
        text-decoration-line: underline;
        color: #7c7c7c;
        margin-bottom: 0;
      }
    }
  }
}
/**
 * Responsive
 */
@media screen and (max-width: $on-min-phone) {
  .container {
    max-width: 288px;
  }
  .h1home h1 {
    font-size: 36px;
    line-height: 49px;
  }

  .project-col {
    justify-content: center;
  }

  .about-us p {
    margin-bottom: 40px;
  }
  .about-us .description {
    text-align: unset;
  }
  .menu-footer .footer-col:last-child {
    padding: 87px 0;
  }
}
@media screen and (max-width: $on-phone) {
  .step-container {
    max-width: 300px;
  }
  .projects h1 {
    max-width: 170px;
    line-height: 40px;
  }
  .project-cols {
    justify-content: center;
  }
  .about-us p {
    margin-bottom: 40px;
  }
  .about-us .description {
    text-align: unset;
    max-width: 288px;
    font-size: 14px;
  }
  .project-cols > div:first-child {
    padding-top: 62px;
  }
  .container {
    max-width: 300px;
  }
  .ticker-wrap {
    width: unset;
  }
}
@media screen and (min-width: 576px) {
  .container {
    width: calc(100% - 50px);
  }
  .h1home h1 {
    font-size: 36px;
    line-height: 43px;
  }
}
@media screen and (max-width: $on-palm) {
  .menu-footer .footer-col:last-child {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
  }
  .line {
    display: none;
  }
  .address-social {
    display: flex;
    max-width: unset;
    flex-wrap: wrap;
    justify-content: unset;
    width: 100%;
  }
  .social-links {
    .social-links {
      padding-left: 0;
      width: 100%;
      justify-content: center;
      display: flex;
    }
  }
  .address {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .social-links a:last-child {
    padding: 12px;
  }
  .disclaimer p {
    padding-top: 50px;
  }
  .container {
    padding-left: unset;
    padding-right: unset;
  }
  .site-nav {
    width: unset;
  }
  // menu
  .menu-right div {
    padding: 5px 16px;
    line-height: 1.5;
  }
  .project-name {
    max-width: 260px;
  }
  .project-image {
    width: 95%;
  }
  .menu-right a {
    font-family: unset;
    text-decoration-line: unset;
  }
  .site-nav > .menu {
    display: block;
    padding-top: 40px;
  }
  .menu-right {
    display: block;
  }

  .h1home h1 {
    font-size: 36px;
    line-height: 49px;
  }
  #menu-bar {
    display: block;
  }
  #menu-cross {
    display: none;
  }
  #menu-bar.show {
    display: none;

    & + svg {
      display: block;
    }
  }

  .projects {
    padding-top: unset;
  }
  #projects .project {
    padding-top: unset;
  }
  .site-nav {
    display: none;
    // top: -200px;
    transition: all 0.5s;
    width: 167px;
    text-align: left;
    &.show {
      display: block;
      background-color: #fff;
      top: 59px;
      width: 100%;
      height: calc(100vh - 59px);
      left: 0;
      right: 0;
      position: fixed;
      max-width: unset;

      z-index: 5;
      .page-link {
        display: block;
        font-family: "Questrial", sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 24px;
        line-height: 33px;
        color: #020113;
        margin-bottom: 48px;
        margin-left: 0;
        border-bottom: none;
        padding: 5px 16px;
      }

      .menu-right {
        padding-top: 16px;

        .lang {
          margin-bottom: 55px;
        }

        .get-in-touch {
          text-decoration: none;

          a {
            font-weight: 200;
            font-size: 24px;
            line-height: 33px;
            color: #020113;
          }
        }
      }

      .disclime-header {
        display: block;
        font-family: "Questrial", sans-serif;
        font-weight: 200;
        font-size: 12px;
        line-height: 21.6px;
        color: #020113;
        margin-top: 55px;
      }
    }
  }

  .page-link {
    border-bottom: 1px solid #e8e8e8;
  }
  #footer-contact-page {
    padding-bottom: 40px;
  }
  #footer-contact-page .disclaimer {
    display: flex;
    padding-top: 40px;
    p {
      padding-top: unset;
    }
  }
  .site-footer {
    padding-bottom: unset;
  }
  .menu-toggle {
    float: right;
    width: 36px;
    height: 6px;
    line-height: 0;
    text-align: center;

    > svg path {
      fill: $border-color-03;
    }
  }
  .nav-trigger {
    padding-right: 16px;
  }

  #contact-form {
    padding-top: 32px;
  }
  // menu

  #footer-contact-page {
    margin: 0 16px;
  }

  .project-cols svg {
    width: 100%;
  }
  .menu-footer {
    flex-wrap: wrap;
  }

  .project-cols {
    display: flex;
  }
  .project-col {
    flex-wrap: wrap;
    display: flex;
  }
  .svg-project {
    max-width: 600px;
  }
  .link {
    flex-wrap: wrap;
    display: flex;
  }
  .what-we-do-links .link p {
    font-size: 32px;
  }
  #get-in-touch a {
    font-size: 42px;
    padding-bottom: 120px;
  }
  #get-in-touch-cookie a {
    font-size: 42px;
    padding-bottom: 120px;
  }
  .contact-title {
    font-size: 56px;
    margin-bottom: 0;
  }
  .contacts {
    flex-wrap: wrap;
  }
  #contact-form {
    margin: 0 5px;
    padding-bottom: 17px;
  }
  .menu-toggle {
    width: 40px;
    padding-right: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .contact-cols > div:first-child {
    display: flex;
    justify-content: center;
  }

  .contacts > .contact-col {
    padding-top: 20px;
  }
  #footer-contact-page {
    border-bottom: unset;
  }
  #services .container {
    display: flex;
    flex-wrap: wrap;
  }
  .who-are-we p {
    padding-top: 80px;
  }

  .clients-testimonials {
    flex-wrap: wrap;
    padding: unset;
    justify-content: center;
  }
  .testimonial .container {
    flex-wrap: wrap;
  }
  .step-container {
    padding-top: unset;
    padding-bottom: unset;
    .container {
      padding: 50px 0;
    }
  }
  .ticker-wrap-team {
    padding-top: unset;
  }
}
@media screen and (max-width: 769px) {
  .cookie-header {
    padding-top: 62px;
    margin: unset;
  }
}
@media screen and (min-width: 769px) {
  // .container {
  //   max-width: 720px;
  // }
  .h1home h1 {
    font-size: 62px;
    line-height: 80px;
  }
  .menu-toggle {
    display: none;
  }
}
@media screen and (min-width: $on-laptop) {
  .h1home h1 {
    font-size: 50px;
    line-height: 64px;
  }
}
@media screen and (max-width: $on-laptop) {
  .logo {
    padding-left: 16px;
  }
  #projects .project-head {
    padding-top: 32px;
    padding-bottom: unset;
  }
  .selected-projects {
    padding-bottom: unset;
  }
  #projects {
    padding-top: 40px;
  }
  #services > .container:nth-child(2) {
    padding-top: 40px;
  }
  #team .service-col .h2-team {
    padding-top: 40px;
  }
  .project-cols {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1400px) {
  .container {
    max-width: 1240px;
  }
  .h1home h1 {
    font-size: 64px;
    line-height: 88.32px;
    font-weight: 400;
    text-align: center;
  }
}

#team {
  border-bottom: 1px solid #e0e0e0;

  .gallery {
    padding: 262px 0 74px;
    display: flex;
    justify-content: space-between;

    .pic {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .pic1 {
      width: 25%;
      margin-top: 39px;
    }

    .pic2 {
      width: 16.6%;
      margin-top: 103px;
    }

    .pic3 {
      width: 25%;
    }

    .pic4 {
      width: 16.6%;
      margin-top: 177px;
    }

    .empty {
      width: 16.6%;
    }
  }

  .h2-team {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
    padding-top: 100px;
  }
  .h2-text-main {
    font-family: "Questrial", sans-serif;

    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }
  .h2-text {
    font-family: "Questrial", sans-serif;

    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #020113;
    margin-bottom: 0px;
  }

  .h3-text {
    font-family: "Questrial", sans-serif;
    max-width: 433px;
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 57.6px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .point-block {
    display: flex;
    align-items: center;
  }

  .wizard-block {
    padding: 120px 0 200px;

    .text-columns {
      display: flex;
      justify-content: space-between;

      .column {
        width: 100%;

        &:first-child {
          margin-right: 69px;
        }
        &:last-child {
          margin-left: 69px;
        }
      }

      p {
        margin-bottom: 40px;
        font-family: "Questrial", sans-serif;
        font-style: normal;
        font-weight: 200;
        font-size: 18px;
        line-height: 28.8px;
        color: #020113;
      }
    }

    .avatars {
      display: flex;
      justify-content: space-between;
      padding-top: 162px;

      .avatar {
        width: 54px;
        height: 54px;
        background-position: center;
      }

      .avatar1 {
        background-image: url("../../assets/images/avatar1.svg");
      }
      .avatar2 {
        background-image: url("../../assets/images/avatar2.svg");
      }
      .avatar3 {
        background-image: url("../../assets/images/avatar3.svg");
      }
      .avatar4 {
        background-image: url("../../assets/images/avatar4.svg");
      }
      .avatar5 {
        background-image: url("../../assets/images/avatar5.svg");
      }
      .avatar6 {
        background-image: url("../../assets/images/avatar6.svg");
      }
      .avatar7 {
        background-image: url("../../assets/images/avatar7.svg");
      }
      .avatar8 {
        background-image: url("../../assets/images/avatar8.svg");
      }
      .avatar9 {
        background-image: url("../../assets/images/avatar9.svg");
      }
    }
  }

  .benefits {
    background-color: #f5f5f5;
    padding: 108px 0 60px;

    .h2-team {
      padding-bottom: 38px;
      border: none;
      padding-top: unset;
    }

    .benefit-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #e0e0e0;
      @media screen and (max-width: 769px) {
        border-top: none;
      }
      .benefit {
        .benefit-pic-m {
          display: flex;
          justify-content: center;

          @media screen and (min-width: 769px) {
            display: none;
          }
        }
        margin: 48px 122px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        p {
          font-family: "Questrial", sans-serif;
          font-style: normal;
          font-weight: 200;
          font-size: 18px;
          line-height: 28.8px;
          color: #06050d;
        }

        p.title {
          font-family: "Questrial", sans-serif;

          font-style: normal;
          font-weight: 200;
          font-size: 28px;
          line-height: 44.8px;
          color: #06050d;
          margin-bottom: 24px;
        }
      }
    }
    .gallery-benefit {
      padding-top: 74px;

      .gallery-row {
        display: flex;
        align-items: center;
        margin-bottom: 124px;

        .pic-block {
          margin: 0 16px;

          &:last-child {
            margin-right: 0;
          }

          &:first-child {
            margin-left: 0;
          }
        }

        .pic1,
        .pic6 {
          width: 42%;
        }

        .pic2,
        .pic5 {
          width: 25%;
        }

        .pic3,
        .pic4 {
          width: 33%;
        }
      }
    }
  }

  .in-touch {
    padding: 163px 0 224px;
    text-align: center;

    div,
    a {
      font-family: "Questrial", sans-serif;

      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 28.8px;
      color: #06050d;
      color: #7c7c7c;
      text-decoration: none;
    }

    .title {
      margin-bottom: 40px;
      font-family: "Questrial", sans-serif;
      font-style: normal;
      font-weight: 200;
      font-size: 56px;
      line-height: 57.68px;
      color: #020113;
    }
  }
}
@media (max-width: $on-pc) {
  .project-cols .project-col {
    max-width: calc(50% - 20px);

    .project svg {
      max-width: 100%;
    }
  }

  .h1home h1 {
    width: unset;
  }

  .ticker-wrap {
    width: 145%;

    .ticker__item {
      font-size: 65px;
    }
  }

  .service-col {
    max-width: 45%;
  }
  #services .h3-text {
    max-width: unset;
  }
  .clients-testimonials {
    padding: 48px 0;
  }

  #team {
    .service-col {
      max-width: 100%;
    }
  }
}
@media (max-width: 1023px) {
  .step-container {
    padding-bottom: 0;
    max-width: 720px;

    .container {
      max-width: unset;
      padding-bottom: 124px;

      .steps {
        width: 100%;
      }
    }
  }
  .project-cols {
    & > div:first-child {
      padding-top: 62px;
    }

    .project-col {
      width: 100%;
      max-width: 100%;
      justify-content: inherit;
      .project {
        width: 100%;
        margin-bottom: 62px;

        .project-name {
          padding-bottom: 0;
        }
      }
    }
  }
  .project-cols > img {
    max-width: calc(90%);
  }
}
@media (max-width: 1336px) {
  .header {
    justify-content: inherit;
  }
  .page-content {
    padding-top: 0;
  }

  .site-nav {
    max-width: 70%;
  }

  .what-we-do-links {
    padding-top: 15px;

    .link {
      display: block;
      p {
        font-size: 24px;
        width: 90%;
      }

      a {
        font-size: 20px;
      }
    }
  }

  .testimonial .testimonial-description {
    padding-left: 16px;
    padding-right: 8px;
    font-size: 16px;
  }

  .get-in-touch-link {
    font-size: 60px;
  }

  .ticker-wrap {
    width: 200%;
  }

  // #services {
  //   padding-top: 40px;
  // }

  #contact-form {
    .form-col,
    .form-group,
    .form-group input,
    .form-col select {
      max-width: unset;
    }
  }

  #footer-contact-page {
    padding: 0;
    border-bottom: none;
  }
  .site-footer.site-footer-contact .container {
    display: block;
    .footer-contact,
    .contacts {
      display: block;
      max-width: unset;
    }

    .contact-col {
      padding: 20px 0;
      border-bottom: 1px solid #ededef;
    }

    .social-links .social-links {
      padding: 20px 0 50px;
    }
  }

  #footer-contact-page .disclaimer {
    display: block;

    p {
      padding: 0;
    }
  }

  #team .gallery {
    padding: 262px 0 125px;
  }

  #team {
    .wizard-block {
      .text-columns {
        display: block;
      }

      .text-columns .column:last-child {
        margin-left: 0;
      }
    }

    .benefits .benefit-row .benefit {
      margin: 48px 48px;
    }
  }

  #projects {
    .project-head {
      display: block;
      padding-bottom: 0;

      .project-col-head {
        max-width: 100%;

        h1 {
          font-size: 52px;
          line-height: 59px;
        }

        p {
          margin-top: 48px;
          font-size: 18px;
          line-height: 160%;
        }
      }
    }

    .project-cols > div:first-child {
      padding-top: 48px;
    }
  }
  .view-all {
    padding-top: unset;
  }
}

@media (max-width: $on-palm) {
  body.fixed {
    position: fixed;
    overflow: hidden;
  }
  .phone-footer {
    padding-bottom: 14px;
  }
  .section-name .h2-text {
    font-size: 14px;
  }
  // body.fixed header {
  //   position: fixed;
  //   background-color: #fff;
  //   width: 100%;
  // }

  header.site-header {
    height: 30px;
    padding: 14px 0;
    max-width: unset;
    min-height: unset;
    line-height: unset;

    .logo {
      font-size: 32px;
    }
  }
  .h1home {
    padding: 54px 0 41px;
  }

  .upwork-top p,
  .about-us .description p {
    font-size: 14px;
  }
  .about-us .upwork-top p {
    font-family: "Questrial", sans-serif;
    font-weight: 200;
    font-size: 14px;
  }
  .about-us .about-service {
    font-size: 16px;

    & + svg {
      width: 16px;
      height: 16px;
    }
  }

  .about-wrap {
    display: flex;
    align-items: center;
  }

  .selected-projects {
    font-size: 28px;
  }
  .projects .container {
    padding-bottom: 56px;
  }
  .page-content .container {
    padding-left: 0px;
  }
  .projects .container {
    padding-left: 16px;
  }
  #what-we-do {
    padding: 72px 0;
  }
  .what-we-do-links .link:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  #get-in-touch p {
    padding-top: 62px;
  }
  // .menu-footer .mail-footer {
  //   padding-top: 60px;
  // }

  .social-links {
    display: flex;
    justify-content: center;
    width: 100%;

    a:first-child {
      padding-left: 0;

      svg {
        padding-left: 0;
      }
    }
  }

  .menu-footer {
    max-width: unset;
    display: flex;
    justify-content: space-between;
    ul.footer-col {
      display: inline-block;
      vertical-align: top;
    }
  }

  .service-col {
    max-width: unset;
    width: 100%;
  }

  .ticker-wrap {
    width: 400%;
    top: 107px;
  }

  .ticker-wrap .ticker__item {
    font-size: 56px;
    line-height: 59px;
  }

  .serices-image {
    text-align: right;
    padding-bottom: 168px;
    padding-top: 16px;
    img {
      height: 296px;
      width: 236px;
      text-align: right;
    }
  }

  .h2-services {
    padding-top: 96px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }

  .h2-services.main-header {
    padding: 0 0 16px 0;
    font-size: 12px;
    border-bottom: 1px solid #e8e8e8;
  }

  .who-are-we p {
    padding-top: 344px;
    font-size: 18px;
    position: absolute;
  }

  #services > .container:nth-child(4) {
    border: none;
  }

  #services .h3-text {
    font-size: 30px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 72px;
  }

  .step-title {
    display: block;
  }

  .step-container {
    max-width: 540px;
  }

  .step-container .container {
    padding-top: 0;
  }

  .clients {
    padding-top: 72px;
    padding-bottom: 0;
  }

  .h2-clients {
    padding-bottom: 24px;
  }

  .clients .h3-text {
    padding: 24px 0 64px;
    border-top: 1px solid #e8e8e8;
  }

  .testimonial {
    padding-bottom: 64px;
    padding-top: 25px;
    border-top: 1px solid #e8e8e8;
    position: relative;
  }

  .clients-testimonials {
    padding-top: 0;
    padding-bottom: 8px;
  }

  .testimonial-image {
    text-align: left;

    img {
      width: 77px;
      height: 77px;
    }
  }

  .testimonial .container {
    justify-content: unset;
  }

  .client-title {
    position: absolute;
    top: 25px;
    left: 109px;
  }

  .testimonial .testimonial-description {
    padding: 32px 0 0;
    font-size: 14px;
    max-width: unset;
  }

  .client-title > p:first-child {
    font-size: 16px;
  }

  #get-in-touch p {
    font-size: 24px;
    padding: 64px 0 41px;
    margin-bottom: 35px;
    line-height: inherit;
  }

  #get-in-touch a {
    line-height: 50px;
  }

  #get-in-touch {
    padding-bottom: 120px;
  }

  #contact-form {
    .contact-cols > div:first-child {
      margin-right: 0;
      border: none;
      max-width: unset;
      justify-content: start;
    }

    .form-group button {
      margin-top: 66px;
    }
  }

  .start-conversation {
    padding: 40px 0 16px;
  }

  .form-group input {
    width: calc(100% - 16px);
  }

  #footer-contact-page {
    .social-links {
      padding: 0;
      .social-links {
        padding-top: 20px;
      }
    }
  }

  #team .h2-team {
    padding-bottom: 14px;
  }

  #team .gallery {
    padding: 32px 0 0px;
    display: block;
  }

  #team .gallery {
    .pic1,
    .pic2,
    .pic3,
    .pic4 {
      margin-right: 0;
      width: 100%;
      margin: 15px 0;
    }
  }

  #team .wizard-block {
    padding: 48px 0;
  }

  #team .h3-text {
    font-size: 28px;
  }

  #team .wizard-block .text-columns p {
    font-size: 18px;
  }

  #team .wizard-block .avatars {
    padding-top: 48px;
    justify-content: center;
  }
  #team .wizard-block .avatars {
    flex-wrap: wrap;

    .avatar {
      margin-bottom: 32px;
      padding: 3px 32px;
      background-repeat: no-repeat;
      margin-bottom: 14px;
    }
    .avatars {
      justify-content: center;
    }
  }
  #team .benefits {
    padding: 60px 0;

    .benefit-row {
      display: block;

      &:last-child {
        border-top: none;
      }

      .benefit {
        margin: 20px 0;

        p.title {
          font-size: 28px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  #team .benefits .gallery-benefit .gallery-row {
    display: none;
    margin-bottom: 0;

    .pic1,
    .pic2,
    .pic3,
    .pic4,
    .pic5,
    .pic6 {
      margin: 12px 0;
      width: 100%;
      text-align: center;
    }
  }

  #team .in-touch {
    padding: 48px 0;

    .title {
      font-size: 48px;
    }
  }

  #projects {
    .projects h1 {
      max-width: 100%;
    }

    .project-cols .project-col:first-child .project:last-child .project-name {
      padding-bottom: 0;
    }

    .project-down {
      padding: 80px 0 126px;

      .project-foot-text {
        text-align: left;

        .h2-text {
          font-weight: 200;
          font-size: 42px;
          line-height: 50px;
        }

        p {
          font-size: 18px;
          line-height: 160%;
        }
      }
    }
  }
  #get-in-touch-cookie .cookie-footer .cookie-footer-text {
    .h2-text {
      font-weight: 200;
      font-size: 42px;
      line-height: 50px;
    }
    a {
      font-size: 18px;
    }
  }
}

@media (max-width: 576px) {
  .container {
    max-width: calc(100% - 50px);
    width: calc(100% - 50px);
  }

  svg rect {
    width: 100%;
  }
}
